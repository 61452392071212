import { GET, POST, PUT, DELETE, DOWNLOAD } from "./requests";

export const auth = {
  login: (data) => {
    return POST("auth/login", data);
  },

  loginGoogle: (data) => {
    return POST("auth/google", data);
  },

  registerGoogle: (data) => {
    return POST("auth/google/complete-registration", data);
  },

  register: (data) => {
    return POST("auth/register", data);
  },

  requestEmailVerification: (data) => {
    return POST("auth/resend-verification-email", data);
  },

  registerVerification: (data) => {
    return POST("auth/register-verification", data);
  },

  requestResetPassword: (data) => {
    return POST("auth/password/request-reset", data);
  },

  resetPassword: (data) => {
    return POST("auth/password/reset", data);
  },

  logout: () => {
    return POST("auth/logout");
  },
};

export const user = {
  getUserData: () => {
    return GET("user");
  },

  getPaymentHistory: () => {
    return GET("user/payment-history");
  },

  updatePersonalData: (data) => {
    return PUT("user/personal-data", null, data);
  },

  updatePassword: (data) => {
    return PUT("user/password", null, data);
  },

  uploadAvatar: (data) => {
    return POST("user/avatar/upload", data, true);
  },

  deleteAvatar: () => {
    return DELETE("user/avatar", "");
  },

  checkUsername: (username) => {
    return GET("check/username", { username });
  },

  checkEmail: (email) => {
    return GET("check/email", { email });
  },

  requestDeleteAccount: (data) => {
    return POST("user/request-delete-account", data);
  },

  actionDeleteAccount: (data) => {
    return POST("user/auth-delete-account", data);
  },
};

export const forms = {
  get: (params) => {
    return GET("forms", params);
  },

  create: (data) => {
    return POST("forms", data);
  },

  update: (id, data) => {
    return PUT("forms", id, data);
  },

  delete: (id) => {
    return DELETE("forms", id);
  },
};

export const projects = {
  get: (params) => {
    return GET("projects", params);
  },

  getById: (id) => {
    return GET(`projects/${id}`);
  },

  create: (data) => {
    return POST("projects", data);
  },

  update: (id, data) => {
    return PUT("projects", id, data);
  },

  delete: (id) => {
    return DELETE("projects", id);
  },
};

export const media = {
  get: () => {
    return GET("media");
  },

  usage: () => {
    return GET("media/usage");
  },

  add: (data) => {
    return POST("media/add", data, true);
  },

  delete: (id) => {
    return DELETE(`media`, id);
  },
};

export const replies = {
  get: (params) => {
    return GET("replies", params);
  },

  getById: (id) => {
    return GET(`replies/${id}`);
  },

  getByProject: (projectId) => {
    return GET(`replies/project/${projectId}`);
  },

  delete: (id) => {
    return DELETE(`replies`, id);
  },

  usage: (params) => {
    return GET("replies/usage", params);
  },

  analytics: (params) => {
    return GET("replies/analytics", params);
  },

  downloadProjectReplies: async (projectId, fileName) => {
    return DOWNLOAD(`replies/download/${projectId}`, fileName);
  },

  downloadPdfReply: (replyId, fileName) => {
    return DOWNLOAD(`replies/${replyId}/export-pdf`, fileName);
  },
};

export const publicEndpoints = {
  getPage: (username, projectSlug) => {
    return GET(`public/projects/${username}/${projectSlug}`);
  },
  sendReply: (username, projectSlug, data) => {
    return POST(`public/projects/${username}/${projectSlug}`, {
      response: JSON.stringify(data),
    });
  },
};

export const payment = {
  createSubscription: (data) => {
    return POST(`paypal/subscribe`, data);
  },
};
