import React from "react";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useStore } from "structure";
import { Icon } from "assets/icons/Icon";
import WebsiteContent from "@lironsheli/website-content";
import { FullPageLoading } from "components/shared/Loading";

import { T } from "util/Translation";

import { AsText } from "ui/common-styles";
import { Button, MenuItem, Tab } from "semantic-ui-react";
import { ResponsePreview } from "./ResponsePreview";
import { useTranslation } from "react-i18next";
import { Confirm } from "ui/Confirm";
import { toast } from "react-toastify";
import { DesignEditor } from "./DesignEditor";
import { DEFAULT_CUSTOM_UI } from "./DEFAULT_CUSTOM_UI";

import * as S from "./styles";

export const ProjectPreview = observer(() => {
  const { t } = useTranslation();
  const { projectsStore } = useStore();
  const [replyContent, setReplyContent] = useState(null);
  const [isDraftConfirmOpen, setIsDraftConfirmOpen] = useState(false);
  const [isPublishConfirmOpen, setIsPublishConfirmOpen] = useState(false);
  let { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [customUi, setCustomUi] = useState(null);
  const [viewIndex, setViewIndex] = useState(0);

  const saveCustomUi = async () => {
    const res = projectsStore.saveProjectSettings(
      projectId,
      {},
      { custom_ui: customUi }
    );
    if (res) {
      toast.success(t("projectPreview.design.saved"));
    } else {
      toast.error(t("error.generic_error"));
    }
  };

  const data_raw = useMemo(() => {
    if (!project) return {};
    let projectForms = [];
    project.settings?.order.forEach((element) => {
      if (element.isVisible) projectForms.push(element.form);
    });

    setCustomUi(project.public_settings?.custom_ui || DEFAULT_CUSTOM_UI);
    return {
      ...project,
      forms: projectForms,
      settings: {
        ...project?.public_settings,
      },
    };
  }, [project]);

  const data = useMemo(() => {
    let d = { ...data_raw };
    if (d.settings) {
      d.settings["custom_ui"] = { ...d.settings?.custom_ui, ...customUi };
    }
    return d;
  }, [data_raw, customUi]);

  const handlePublicProject = async (isPublic) => {
    await projectsStore
      .update(projectId, { isPublic })
      .then(() => projectsStore.getById(projectId))
      .then((res) => setProject(res));
    toast.success(isPublic ? t("project.published") : t("project.unpublished"));
  };

  useEffect(() => {
    projectsStore.getById(projectId).then((res) => setProject(res));
    // eslint-disable-next-line
  }, []);

  const panes = [
    {
      menuItem: (
        <MenuItem key="design">
          <T id="project.preview.designTitle" />
        </MenuItem>
      ),
      render: () => (
        <DesignEditor setCustomUi={setCustomUi} initialValues={customUi} />
      ),
    },
    {
      menuItem: (
        <MenuItem key="response" disabled={!replyContent}>
          <T id="project.preview.responseTitle" />
        </MenuItem>
      ),
      render: () => <ResponsePreview data={replyContent} />,
    },
  ];

  if (!project) {
    return <FullPageLoading />;
  }

  return (
    <div>
      <S.PreviewHeader>
        <AsText as={Link} to={`/project/${projectId}`}>
          <Icon name="left" height={20} />
          <T id="project.back_to_management" />
        </AsText>

        <p className="ls-hide-s">
          <T id="project.preview_title" />
        </p>
        {project.isPublic ? (
          <Button
            className="ls-bright"
            color="green"
            onClick={() => saveCustomUi()}
          >
            <T id="project.update" />
          </Button>
        ) : (
          <Button
            className="ls-bright"
            color="green"
            onClick={() => setIsPublishConfirmOpen(true)}
          >
            <T id="project.publish_btn" />
          </Button>
        )}
      </S.PreviewHeader>
      <S.PreviewBody>
        {viewIndex === 0 && (
          <>
            <div className="ls-preview">
              <WebsiteContent data={data} setReplyContent={setReplyContent} />
            </div>
            <div className="ls-editor">
              <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
            </div>
          </>
        )}
        {viewIndex === 1 && <div className="ls-view">{panes[0].render()} </div>}
        {viewIndex === 2 && <div className="ls-view">{panes[1].render()} </div>}
        <div className="ls-view-navigator-space-saver"></div>
        <div className="ls-view-navigator">
          <Button
            className={viewIndex === 0 ? "ls-active" : ""}
            basic
            onClick={() => setViewIndex(0)}
          >
            Preview
          </Button>
          <Button
            className={viewIndex === 1 ? "ls-active" : ""}
            basic
            onClick={() => setViewIndex(1)}
          >
            Design
          </Button>
          <Button
            className={viewIndex === 2 ? "ls-active" : ""}
            basic
            onClick={() => setViewIndex(2)}
            disabled={!replyContent}
          >
            Response
          </Button>
        </div>
      </S.PreviewBody>

      <Confirm
        title={t("project.publish_title")}
        text={t("project.publish_confirmation")}
        confirmButton={t("project.confirm_publish")}
        open={isPublishConfirmOpen}
        onCancel={() => setIsPublishConfirmOpen(false)}
        onConfirm={() => handlePublicProject(true)}
      />

      <Confirm
        title={t("project.unpublish_title")}
        text={t("project.unpublish_confirmation")}
        confirmButton={t("project.confirm_unpublish")}
        open={isDraftConfirmOpen}
        onCancel={() => setIsDraftConfirmOpen(false)}
        onConfirm={() => handlePublicProject(false)}
      />
    </div>
  );
});
