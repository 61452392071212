import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "structure";
import { Form } from "react-final-form";
import { Button, Modal } from "semantic-ui-react";
import { MediaItem } from "./MediaItem";
import { toast } from "react-toastify";
import { API_URL } from "util/consts";

import { useTranslation } from "react-i18next";
import { T } from "util/Translation";

import * as S from "./styles";

export const MediaGalleryModal = observer(
  ({ onSelect, initialValue, trigger = null, multiple, isRequired }) => {
    const { t } = useTranslation();
    const { mediaStore } = useStore();
    let inputRef = useRef(null);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedImages, setSelectedImages] = useState(initialValue || []);

    const handleImageClick = (item) => {
      if (multiple) {
        if (selectedImages.includes(item)) {
          setSelectedImages(selectedImages.filter((x) => x !== item));
        } else {
          setSelectedImages([...selectedImages, item]);
        }
      } else {
        if (selectedImages?.[0] === item) {
          setSelectedImages([]);
        } else {
          setSelectedImages([item]);
        }
      }
    };

    useEffect(() => {
      if (!(mediaStore.media?.images?.length > 0)) mediaStore.fetchMedia();
      // eslint-disable-next-line
    }, []);

    const onSubmit = () => {
      if (selectedFile) {
        mediaStore
          .addImage(selectedFile)
          .then(() => {
            toast.success(t("media_modal.upload_success"));
            inputRef.current.value = null;
            setSelectedFile(null);
          })
          .catch((error) => {
            toast.success(t("media_modal.upload_error"));
            console.error("Error uploading file:", error);
          });
      }
    };

    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]); // Set the selected file in state
    };

    const gallery = mediaStore?.media?.images || [];

    return (
      <Modal
        open={isOpenModal}
        onOpen={() => setIsOpenModal(true)}
        onClose={() => setIsOpenModal(false)}
        closeOnDimmerClick
        size="large"
        trigger={trigger}
      >
        <S.GalleryMediaWrapper>
          <p>
            <T id="media_modal.select_image" />
          </p>
          <S.GalleryWrapper>
            <div className="gallery-grid">
              {gallery.map((item) => (
                <MediaItem
                  key={item}
                  item={item}
                  handleImageClick={onSelect ? handleImageClick : null}
                  selectedImages={selectedImages}
                />
              ))}
            </div>

            <div className="gallery-upload">
              <div style={{ float: "right", paddingBottom: 16 }}>
                <Button
                  basic
                  content={t("media_gallery.cancel")}
                  onClick={() => {
                    setIsOpenModal(false);
                  }}
                  style={{ marginRight: 16 }}
                />
                <Button
                  onClick={() => {
                    onSelect(
                      selectedImages.map(
                        (selectedImage) => `${API_URL}${selectedImage}`
                      )
                    );
                    setIsOpenModal(false);
                  }}
                  primary
                  content={t("media_gallery.select")}
                  disabled={isRequired && selectedImages.length === 0}
                />
              </div>

              <Form
                onSubmit={onSubmit}
                render={({ handleSubmit, submitting }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <input
                        ref={inputRef}
                        type="file"
                        name="image"
                        onChange={handleFileChange}
                      />
                      <Button
                        type="submit"
                        primary
                        content={t("media_gallery.upload")}
                        disabled={submitting || !selectedFile}
                        loading={submitting}
                      />
                    </form>
                  );
                }}
              />
            </div>
          </S.GalleryWrapper>
        </S.GalleryMediaWrapper>
      </Modal>
    );
  }
);
