import { Field } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";
import {
  Checkbox,
  Button,
  Dropdown as SemanticDropdown,
} from "semantic-ui-react";
import * as S from "./styles";
import { FieldArray } from "react-final-form-arrays";
import { Icon } from "assets/icons/Icon";

import { useTranslation } from "react-i18next";
import { T } from "util/Translation";

export const DropdownSettings = ({ blockId }) => {
  const { t } = useTranslation();

  const selectionOptions = [
    {
      key: "single",
      text: t("dropdown_settings.selection.single"),
      value: "single",
    },
    {
      key: "multi",
      text: t("dropdown_settings.selection.multi"),
      value: "multi",
    },
  ];

  return (
    <S.SectionWrapper>
      <li>
        <Field name={`${blockId}.tool-5.required`}>
          {(props) => (
            <S.Field>
              <Checkbox
                name={props.input.name}
                checked={props.input.value ? true : false}
                onChange={(e, data) => {
                  props.input.onChange(data.checked);
                }}
                label={t("dropdown_settings.required")}
              />
            </S.Field>
          )}
        </Field>
      </li>
      <li>
        <Field name={`${blockId}.tool-5.selection`}>
          {(props) => (
            <S.Field>
              <label>
                <T id="dropdown_settings.selection_label" />
              </label>
              <SemanticDropdown
                name={props.input.name}
                value={props.input.value}
                onChange={(e, data) => {
                  props.input.onChange(data.value);
                }}
                fluid
                search
                selection
                options={selectionOptions}
              />
            </S.Field>
          )}
        </Field>
      </li>
      <li>
        <FieldArray name={`${blockId}.tool-5.options`} defaultValue={[]}>
          {({ fields }) => {
            return (
              <S.DropdownOptions>
                <h6>
                  <T id="dropdown_settings.options" />
                </h6>
                {fields.map((name, idx) => (
                  <Field key={`${name}-${idx}`} name={name}>
                    {(props) => (
                      <S.Field className="ls-field">
                        <TextareaAutosize
                          name={props.input.name}
                          value={props.input.value}
                          onChange={props.input.onChange}
                          className="ls-contentEditable"
                          placeholder={t(
                            "dropdown_settings.option_placeholder",
                            { number: idx + 1 }
                          )}
                        />
                        <Button
                          className="remove-option ls-bright"
                          type="button"
                          color="red"
                          onClick={() => fields.remove(idx)}
                        >
                          <Icon name="delete" />
                        </Button>
                      </S.Field>
                    )}
                  </Field>
                ))}
                <br />
                <Button
                  primary
                  className="ls-bright ls-split"
                  fluid
                  compact
                  type="button"
                  onClick={() => fields.push("")}
                >
                  <span>
                    <T id="dropdown_settings.add_option" />
                  </span>
                  <Icon name="add" />
                </Button>
              </S.DropdownOptions>
            );
          }}
        </FieldArray>
      </li>
    </S.SectionWrapper>
  );
};
