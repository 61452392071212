import { useState, useEffect } from "react";
import { Button } from "semantic-ui-react";
import { ConcentWrapper } from "styles";
import { MAIN_URL } from "util/consts";
import { T } from "util/Translation";

export const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setShowConsent(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setShowConsent(false);
  };

  if (!showConsent) return null;

  return (
    <ConcentWrapper>
      <div>
        <h6>
          <T id="cookieConsent.title" />
        </h6>
        <p>
          <T id="cookieConsent.message" />{" "}
          <a
            href={`${MAIN_URL}privacy-policy/`}
            rel="noreferrer"
            target="_blank"
          >
            <T id="cookieConsent.readMore" />
          </a>
        </p>
      </div>

      <Button primary size="small" onClick={handleAccept}>
        <T id="cookieConsent.accept" />
      </Button>
    </ConcentWrapper>
  );
};
