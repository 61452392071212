import { Link, useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { MenuActions } from "./MenuActions";

import { T } from "util/Translation";

import { FormPreviewWrapper } from "./styles";

export const FormPreview = ({ data, idx, stage, isVisible, lcoalStore }) => {
  const { projectId } = useParams();
  const indexRef = useRef(idx);

  // eslint-disable-next-line
  const order = lcoalStore.order;

  // eslint-disable-next-line
  const up = async () => {
    lcoalStore.replaceOrder(idx, idx - 1);
  };

  // eslint-disable-next-line
  const down = async () => {
    lcoalStore.replaceOrder(idx, idx + 1);
  };

  useEffect(() => {
    indexRef.current = idx;
  }, [idx]);

  const animationClass =
    indexRef.current > idx
      ? "animation-up"
      : indexRef.current < idx
      ? "animation-down"
      : "";

  return (
    <FormPreviewWrapper
      isVisible={isVisible}
      key={idx}
      className={animationClass}
    >
      <div className="ls-form-info">
        <Link to={`/project/${projectId}/form/${data.id}`}>
          <div className="ls-icon-circle-primary">
            <span>{data.name.substring(0, 1)}</span>
          </div>
          <div className="ls-info">
            <p className="ls-name">{data.name}</p>
            <div
              className={
                "ls-stage-tag " +
                (isVisible ? "ls-stage-tag-on" : "ls-stage-tag-off")
              }
            >
              {isVisible ? (
                <T id="form_preview.stage_number" values={{ stage }} />
              ) : (
                <T id="form_preview.hidden" />
              )}
            </div>
          </div>
        </Link>
        <div className="ls-icon-circle-menu">
          <MenuActions
            step={data}
            idx={idx}
            stage={stage}
            isVisible={isVisible}
            projectId={projectId}
            lcoalStore={lcoalStore}
          />
        </div>
      </div>
    </FormPreviewWrapper>
  );
};
