import { useTranslation } from "react-i18next";
import StyleFields from "./StyleFields";
import { Accordion } from "semantic-ui-react";

const ButtonSettings = () => {
  const { t } = useTranslation();

  const sectionPanels = [
    {
      key: "part-1",
      title: t("projectPreview.design.button.section.conteiner"),
      content: {
        content: <PartConteiner />,
      },
    },
    {
      key: "part-2",
      title: t("projectPreview.design.button.section.fonts"),
      content: {
        content: <PartFonts />,
      },
    },
    {
      key: "part-3",
      title: t("projectPreview.design.button.section.border"),
      content: {
        content: <PartBorder />,
      },
    },
    {
      key: "part-4",
      title: t("projectPreview.design.button.section.color"),
      content: {
        content: <PartBasicColor />,
      },
    },
    {
      key: "part-5",
      title: t("projectPreview.design.button.section.text"),
      content: {
        content: <PartText />,
      },
    },
  ];

  return (
    <>
      <Accordion defaultActiveIndex={0} panels={sectionPanels} styled />
    </>
  );
};

const PartConteiner = () => (
  <>
    <StyleFields.Allignment
      name="button.alignment"
      labelIntl="projectPreview.design.button.alignment"
    />
    <StyleFields.Width
      name="button.width"
      labelIntl="projectPreview.design.button.width"
      smallSize={120}
    />
  </>
);
const PartFonts = () => (
  <>
    <StyleFields.FontStyle
      name="button.fontStyle"
      labelIntl="projectPreview.design.button.font_style"
    />
    <StyleFields.FontSize
      name="button.fontSize"
      labelIntl="projectPreview.design.button.font_size"
    />
  </>
);

const PartBasicColor = () => (
  <>
    <StyleFields.Color
      name="button.color"
      labelIntl="projectPreview.design.button.color"
      sub
    />
  </>
);

const PartText = () => (
  <>
    <StyleFields.Text
      name="button.nextButtonText"
      labelIntl="projectPreview.design.button.text.next"
    />
    <StyleFields.Text
      name="button.prevButtonText"
      labelIntl="projectPreview.design.button.text.next"
    />
    <StyleFields.Text
      name="button.submitButtonText"
      labelIntl="projectPreview.design.button.text.submit"
    />
  </>
);

const PartBorder = () => (
  <>
    <StyleFields.Border
      name="button.border"
      labelIntl="projectPreview.design.button.border"
    />
    <StyleFields.Radius
      name="button.radius"
      labelIntl="projectPreview.design.button.radius"
    />
    <StyleFields.Shadow
      name="button.shadow"
      labelIntl="projectPreview.design.button.shadow"
    />
  </>
);

export default ButtonSettings;
