import styled from "styled-components";
import p2 from "assets/illustrations/p2.svg";

export const Container = styled.div`
  min-height: 100dvh;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background: var(--white);
  padding: 80px 0px;
  margin: 0px auto 0px 36vw;

  .ls-bg-elem {
    position: absolute;
    height: calc(100dvh - 32px);
    width: 35vw;
    top: 16px;
    left: 16px;
    border-radius: 16px;
    background: var(--secondary-100);

    .ls-logo {
      position: absolute;
      top: 16px;
      left: 20px;
    }

    .ls-illustration {
      position: absolute;
      bottom: 0px;
      height: 100vh;
      right: -50vh;
      transform: rotate(180deg);
    }
  }

  .ls-outlet-wrapper {
    margin: auto 32px;
    max-width: calc(100vw - 64px);
  }

  .ls-message {
    margin-top: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    gap: 8px;
    z-index: 9;

    p {
      margin: 0;
      color: var(--grey-500);
    }
    a {
      color: var(--grey-800);
      text-decoration: none;
      font-weight: 700;
      :hover {
        color: var(--grey-900);
      }
    }
  }

  @media only screen and (max-width: 800px) {
    margin: 0px auto;
    background-image: url(${p2});
    background-position: -25vw 50vh;
    background-size: 140vw;
    background-repeat: no-repeat;
    box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.3);

    .ls-bg-elem {
      display: none;
    }
  }

  @media only screen and (max-width: 600px) {
    background-position: -45vw 60vh;
    background-size: 165vw;

    .ls-message {
      bottom: -40px;
      top: auto;
    }
  }

  .ls-login,
  .ls-register,
  .ls-complete,
  .ls-confirm,
  .ls-reset-password {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-direction: column;

    h1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      color: var(--primary-500);
      margin-bottom: 16px;
      span {
        font-size: 32px;
        font-weight: 700;
      }
    }
  }

  .ls-body {
    display: flex;
    gap: 16px;
    width: 400px;
    max-width: 100%;
    align-items: stretch;
    flex-direction: column;
  }

  .ls-section-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ls-complete .ls-body {
    width: 642px;
  }

  .ls-reset-password .ls-body.ls-details {
    grid-template-columns: 1fr;
    width: 360px;
  }

  .ui.form {
    .ls-submit-error {
      color: var(--toastify-color-error);
      text-align: center;
      background: #ffe9e7;
      margin: 12px 0;
    }
    > .ui.button {
      margin-top: 24px;
    }
  }

  .ls-register-types {
    display: flex;
    flex-direction: column;
    gap: 24px;

    button {
      backgorund: var(--grey-50);
      border: 1px solid var(--grey-200);
      color: var(--grey-700);
      padding: 16px 64px;
    }
  }

  .ls-confirm {
    .ls-email span {
      font-weight: 600;
      color: var(--secondary-600);
    }

    .ls-resend {
      display: flex;
      align-items: center;
      gap: 12px;

      p {
        margin: 0;
      }
      .button {
        padding: 4px 0;
      }
    }
  }

  .ls-disclaimer {
    text-align: center;
    font-size: 0.9rem;
    text-wrap-style: pretty;
    padding: 8px;
    a {
      font-size: 0.9rem;
    }
  }
`;

export const GoogleAccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--grey-300);

  > img {
    height: 80px;
    width: 80px;
    border-radius: 50%;
  }

  .ls-account-content {
    font-size: 1.17em;
  }
`;
