import { Form as FormUI, Button } from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import { Form } from "react-final-form";
import { FieldText } from "ui/FieldText";
import { useStore } from "structure";
import {
  multiValidators,
  required,
  min,
  max,
  equals,
  password,
} from "util/validators";
import { useState } from "react";
import { toast } from "react-toastify";
import { T } from "util/Translation";
import { useTranslation } from "react-i18next";

export const ResetPassword = () => {
  const { t } = useTranslation();
  const { accountStore } = useStore();
  const history = useHistory();
  const [email, setEmail] = useState("");

  const handleRequestResetPassword = async (data) => {
    const res = await accountStore.requestResetPassword({
      email: data.email,
    });
    if (res.error) {
      return { code: t("reset_password.error_request") };
    } else {
      setEmail(data.email);
    }
  };

  const handleUpdatePassword = async (data) => {
    const res = await accountStore.resetPassword({
      email,
      password: data.password,
      code: data.code,
      password_confirmation: data.password_confirmation,
    });
    if (res?.status === "error") {
      return { code: t("reset_password.error_update") };
    } else {
      toast.success(t("reset_password.success"));
      history.push("/login");
    }
  };

  return (
    <>
      <div className="ls-outlet-wrapper">
        <div className="ls-reset-password">
          <h1>
            <T id="reset_password.title" />
          </h1>
          {!email && (
            <div className="ls-body ls-details">
              <Form
                onSubmit={handleRequestResetPassword}
                render={({ handleSubmit, submitting }) => (
                  <FormUI onSubmit={handleSubmit}>
                    <FieldText
                      finalFormProps={{
                        name: "email",
                        validate: multiValidators([required, min(6), max(255)]),
                      }}
                      semanticProps={{
                        disabled: submitting,
                        type: "email",
                      }}
                      label={t("reset_password.enter_email")}
                    />

                    <Button
                      type="submit"
                      primary
                      disabled={submitting}
                      loading={submitting}
                      fluid
                    >
                      {t("reset_password.reset_button")}
                    </Button>
                  </FormUI>
                )}
              />
            </div>
          )}
          {!!email && (
            <Form
              onSubmit={handleUpdatePassword}
              render={({ handleSubmit, submitting }) => (
                <FormUI onSubmit={handleSubmit}>
                  <div className="ls-body">
                    <div className="ls-section">
                      <p>
                        <T id="reset_password.check_email" values={{ email }} />
                      </p>
                      <p>
                        <T id="reset_password.code_validity" />
                      </p>
                      <div>
                        <p>
                          <T id="reset_password.no_code" />
                        </p>
                        <Button
                          type="button"
                          basic
                          disabled={submitting}
                          onClick={() => setEmail("")}
                        >
                          <T id="reset_password.change_email" />
                        </Button>
                        <Button
                          type="button"
                          basic
                          disabled={submitting}
                          onClick={() => handleRequestResetPassword({ email })}
                        >
                          <T id="reset_password.resend_code" />
                        </Button>
                      </div>
                    </div>
                    <div className="ls-section">
                      <FieldText
                        finalFormProps={{
                          name: "code",
                          validate: multiValidators([required, min(6), max(6)]),
                        }}
                        semanticProps={{
                          disabled: submitting,
                        }}
                        label={t("reset_password.enter_code")}
                      />

                      <FieldText
                        finalFormProps={{
                          name: "password",
                          validate: multiValidators([
                            required,
                            password,
                            max(255),
                          ]),
                        }}
                        semanticProps={{
                          disabled: submitting,
                          type: "password",
                        }}
                        label={t("reset_password.new_password")}
                      />
                      <FieldText
                        finalFormProps={{
                          name: "password_confirmation",
                          validate: multiValidators([
                            required,
                            equals("password"),
                          ]),
                        }}
                        semanticProps={{
                          disabled: submitting,
                          type: "password",
                        }}
                        label={t("reset_password.confirm_password")}
                      />

                      <Button
                        primary
                        type="submit"
                        disabled={submitting}
                        loading={submitting}
                        fluid
                      >
                        {t("reset_password.update_button")}
                      </Button>
                    </div>
                  </div>
                </FormUI>
              )}
            />
          )}
        </div>
      </div>
      <div className="ls-message">
        <p>
          <T id="reset_password.remembered" />
        </p>{" "}
        <Link to="/login">
          <T id="reset_password.login" />
        </Link>
      </div>
    </>
  );
};
