import { observer } from "mobx-react-lite";

import { useStore } from "structure";
import { Button, Dropdown } from "semantic-ui-react";
import { Icon } from "assets/icons/Icon";

import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Confirm } from "ui/Confirm";
import { Link } from "react-router-dom";
import { useForm } from "react-final-form";

export const MenuActions = observer(({ sectionId, isVisible, projectId }) => {
  const { accountStore } = useStore();
  const { currentAccount } = accountStore;
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(null);
  const form = useForm();

  const handleVisiblity = (isVisible) => {
    form.change("isActive", isVisible);
    form.submit();
  };

  return (
    <>
      <Dropdown
        trigger={
          <Button className="ls-bright ls-icon" type="button" color="grey">
            <Icon name="menu" />
          </Button>
        }
        disabled={currentAccount?.isLoading}
        floating
        direction="left"
        icon={null}
      >
        <Dropdown.Menu>
          <Dropdown.Item
            as={Link}
            text={t("formSection.menuActions.edit")}
            icon={<Icon name="edit" height={20} />}
            to={`/project/${projectId}/part/${sectionId}`}
          />
          {isVisible ? (
            <Dropdown.Item
              text={t("formSection.menuActions.hide")}
              icon={<Icon name="unpublished" height={20} />}
              onClick={() => setModalOpen("hide")}
            />
          ) : (
            <Dropdown.Item
              text={t("formSection.menuActions.visible")}
              icon={<Icon name="published" height={20} />}
              onClick={() => setModalOpen("visible")}
            />
          )}
        </Dropdown.Menu>
      </Dropdown>

      <Confirm
        title={t("interface.hide_section_title")}
        text={t("interface.hide_section_text")}
        confirmButton={t("interface.confirm_hide_section")}
        color="red"
        open={modalOpen === "hide"}
        onCancel={() => setModalOpen(null)}
        onConfirm={() => handleVisiblity(false)}
      />

      <Confirm
        title={t("interface.visible_section_title")}
        text={t("interface.visible_section_text")}
        confirmButton={t("interface.confirm_visible_section")}
        color="green"
        open={modalOpen === "visible"}
        onCancel={() => setModalOpen(null)}
        onConfirm={() => handleVisiblity(true)}
      />
    </>
  );
});
