import { observer } from "mobx-react-lite";
import { useStore } from "structure";
import { useHistory } from "react-router-dom";
import { useRef, useState } from "react";
import { toast } from "react-toastify";

import { CreateFormModal } from "components/shared/CreateFormModal";
import { FormPreview } from "./FormPreview";
import { GeneralEdit } from "ui/common-styles";

import { Icon } from "assets/icons/Icon";

import { useTranslation } from "react-i18next";
import { T } from "util/Translation";

import * as S from "./styles";

export const Forms = observer(({ lcoalStore, project }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const projectId = project?.id;
  const { projectsStore } = useStore();
  const countStage = useRef(0);
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);

  const createNewForm = async (name) => {
    const newId = await projectsStore.createNewForm(projectId, name);
    if (newId) {
      toast.success(t("forms.new_form_success"));
      history.push(`/project/${projectId}/form/${newId}`);
    } else {
      toast.error(t("forms.new_form_error"));
    }
  };

  return (
    <GeneralEdit>
      <div className="ls-split">
        <h4>
          <T id="forms.title" />
        </h4>
      </div>
      <p>
        <T id="forms.description" />
      </p>
      <div className="ls-main-block">
        <S.FormsWrapper>
          <S.NewFormButton basic onClick={() => setIsNewModalOpen(true)}>
            <Icon name="addNote" height={20} width={20} />
            <T id="forms.create_new_form" />
          </S.NewFormButton>
          {lcoalStore.order.map((item, idx) => {
            if (!item?.form) return null;
            if (idx === 0) countStage.current = 0;
            if (item.isVisible) countStage.current = countStage.current + 1;
            return (
              <FormPreview
                key={item.form.id}
                data={item.form}
                idx={idx}
                stage={countStage.current}
                projectId={projectId}
                isVisible={item.isVisible}
                lcoalStore={lcoalStore}
              />
            );
          })}
          {lcoalStore.order.length === 0 && (
            <p>
              <T id="forms.no_forms" />
            </p>
          )}
        </S.FormsWrapper>
      </div>

      <CreateFormModal
        open={isNewModalOpen}
        setOpen={setIsNewModalOpen}
        handleCreate={createNewForm}
      />
    </GeneralEdit>
  );
});
