import styled from "styled-components";

export const FormPreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: white;
  border-radius: 4px;
  border: 1px solid var(--grey-300);
  cursor: pointer;
  transition: 0.2s;

  ${({ isVisible }) => (!isVisible ? "z-index: 1; " : "")}

  .ls-form-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    a {
      display: flex;
      align-items: center;
      flex: 1;
      gap: 16px;
      color: var(--grey-800);
      padding: 8px 24px;
      text-decoration: none;
    }

    > div {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .ls-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      p {
        font-size: 12px;
        margin: 0;
        text-decoration: none;
      }

      .ls-name {
        font-size: 16px;
        font-weight: 700;
      }
    }

    .ls-icon-circle-primary {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 100%;
      background: ${({ isVisible, singlePart }) =>
        singlePart
          ? isVisible
            ? "var(--tertiary-200)"
            : "var(--tertiary-100)"
          : isVisible
          ? "var(--secondary-200)"
          : "var(--secondary-50)"};
      transition: 0.2s;

      > span {
        text-transform: uppercase;
        font-size: 22px;
        font-weight: 300;
        color: ${({ isVisible, singlePart }) =>
          singlePart
            ? isVisible
              ? "var(--tertiary-700)"
              : "var(--tertiary-500)"
            : isVisible
            ? "var(--secondary-700)"
            : "var(--secondary-300)"};
      }
    }

    .ls-icon-circle-menu button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      padding: 0;
      margin: 0px 24px;
      border-radius: 100%;
      background: white !important;
      border: none !important;
      transition: 0.2s;

      > svg {
        fill: var(--grey-700) !important;
      }
    }

    .ls-icon-circle-go {
      display: none;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background: var(--white);
      transition: 0.2s;

      > svg {
        fill: var(--grey-700);
      }
    }

    .ls-stage-tag {
      padding: 2px 8px;
      font-size: 12px;
      font-weight: 600;
      color: white;
      border-radius: 4px;
      letter-spacing: 0.24px;
    }

    .ls-stage-tag-on {
      background: var(--green-500);
    }

    .ls-stage-tag-off {
      background: var(--grey-400);
    }
  }

  .ls-form-actions {
    opacity: 0;
    height: 0;
    margin-bottom: 0;
    overflow: hidden;
    display: grid;
    gap: 8px;
    justify-content: center;
    grid-template-columns: ${({ singlePart }) =>
      singlePart ? "1fr" : "1fr 1fr 1fr"};
    padding: 0 24px;
    transition: 0.2s;

    > button {
      display: flex;
      flex-direction: row;
      gap: 8px;
      padding: 4px 8px;
      background: var(--white);
      color: var(--grey-800);
      align-items: center;
      justify-content: center;
      font-size: 16px;

      > svg {
        height: 16px;
        width: 16px;
        fill: var(--grey-400);
      }
    }
  }

  &:hover {
    background: var(--grey-200);
    box-shadow: var(--shadow-200);

    .ls-form-actions {
      opacity: 1;
      height: 32px;
      margin-bottom: 8px;
    }

    .ls-icon-circle-go {
      display: flex;
    }
  }

  &.animation-up {
    animation: goUp 0.6s;
    z-index: 2;
  }

  &.animation-down {
    animation: goDown 0.6s;
  }

  @keyframes goUp {
    from {
      transform: translateY(80px);
    }
    to {
      transform: translateY(0px);
    }
  }

  @keyframes goDown {
    from {
      transform: translateY(-80px);
    }
    to {
      transform: translateY(0px);
    }
  }

  @media only screen and (max-width: 700px) {
    .ls-form-info {
      pointer-events: none;
      > div:last-child {
        pointer-events: bounding-box;
      }
    }
    .ls-form-actions {
      grid-template-columns: 1fr;
    }
    &:hover {
      .ls-form-actions {
        height: 118px;
      }
    }
  }
`;

export const FormsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px; 
    max-width: min(100%, 1200px);
    margin-bottom: 16px;
}
`;

export const NewFormButton = styled.button`
  width: 100%;
  background: var(--primary-50) !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 24px;
  height: 64px;
  border: 1px solid var(--primary-700);
  color: var(--primary-700) !important;
  font-weight: 700;
  font-size: 16px;
  gap: 16px;
  transition: box-shadow 0.2s;

  svg {
    height: 48px;
    width: 48px;
    padding: 12px;
    background: var(--primary-500);
    border-radius: 100%;
    fill: var(--white);
  }

  :hover {
    box-shadow: 0px 2px 12px #0e749028;
    background: var(--primary-50) !important;
  }
`;
