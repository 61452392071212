import { observer } from "mobx-react-lite";
import { useState } from "react";

import { useStore } from "structure";
import { Form } from "react-final-form";

import { Button, Modal } from "semantic-ui-react";
import { FieldText } from "ui/FieldText";
import { ModalCloseWrapper, ModalContentWrapper } from "ui/common-styles";
import { Icon } from "assets/icons/Icon";

import { useTranslation } from "react-i18next";
import { T } from "util/Translation";

export const DeleteAccountModal = observer(({ open, setOpen }) => {
  const { t } = useTranslation();
  const { accountStore } = useStore();
  const [step, setStep] = useState(0);

  const handleRequest = async (data) => {
    if (data.email !== accountStore.currentAccount?.email) {
      return { email: "Email is not match to this account" };
    }
    await accountStore.requestDeleteAccount(data);
    setStep(1);
  };

  const handleDelete = async (data) => {
    await accountStore.actionDeleteAccount(data);
  };

  return (
    <>
      <Modal
        size="tiny"
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        closeIcon={
          <ModalCloseWrapper>
            <Icon name="close" />
          </ModalCloseWrapper>
        }
      >
        {step === 0 && (
          <Form
            onSubmit={handleRequest}
            render={({ handleSubmit, submitting, submitError }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <ModalContentWrapper>
                    <div className="ls-content">
                      <h3>
                        <T id="account_delete.delete_title" />
                      </h3>
                      <p>
                        <T id="account_delete.delete_warning" />
                      </p>
                      <div className="ls-mark">
                        <FieldText
                          label={t("account_delete.email")}
                          finalFormProps={{
                            name: "email",
                          }}
                        />
                      </div>
                    </div>
                    <div className="ls-actions">
                      <Button
                        type="submit"
                        className="ls-bright"
                        color="red"
                        content={t("account_delete.delete_button")}
                        disabled={submitting}
                        loading={submitting}
                      />
                      <Button
                        type="button"
                        className="ls-bright"
                        color="grey"
                        content={t("account_delete.cancel_button")}
                        disabled={submitting}
                        onClick={() => setOpen(false)}
                      />
                    </div>
                  </ModalContentWrapper>
                </form>
              );
            }}
          />
        )}
        {step === 1 && (
          <Form
            onSubmit={handleDelete}
            render={({ handleSubmit, submitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <ModalContentWrapper>
                    <div className="ls-content">
                      <h3>
                        <T id="account_delete.delete_auth_title" />
                      </h3>
                      <p>
                        <T id="account_delete.delete_auth_warning" />
                      </p>
                      <div className="ls-mark">
                        <FieldText
                          label={t("account_delete.otp")}
                          finalFormProps={{
                            name: "otp",
                          }}
                        />
                      </div>
                    </div>
                    <div className="ls-actions">
                      <Button
                        type="submit"
                        className="ls-bright"
                        color="red"
                        content={t("account_delete.complete_delete_button")}
                        disabled={submitting}
                        loading={submitting}
                      />
                      <Button
                        type="button"
                        className="ls-bright"
                        color="grey"
                        content={t("account_delete.back_button")}
                        disabled={submitting}
                        onClick={() => setStep(0)}
                      />
                    </div>
                  </ModalContentWrapper>
                </form>
              );
            }}
          />
        )}
      </Modal>
    </>
  );
});
