import { observer } from "mobx-react-lite";
import * as S from "./styles.js";
import { T } from "util/Translation";

export const PanelTemplate = observer(({ pages }) => {
  return (
    <S.PaneWrapper>
      {pages.map((page, idx) => (
        <div key={idx} className={`ls-content`}>
          {page?.content || null}
        </div>
      ))}
    </S.PaneWrapper>
  );
});

export const ActiveTag = ({ isActive }) => {
  return (
    <span className={`ls-tag ${isActive ? "ls-active" : ""}`}>
      <T id={isActive ? "status.active" : "status.disabled"} />
    </span>
  );
};
