import { useState } from "react";
import { Button } from "semantic-ui-react";
import { T } from "util/Translation";

import * as S from "./styles";
import { DeleteAccountModal } from "./DeleteAccountModal";

export const DeleteAccount = () => {
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  return (
    <>
      <S.FieldsSectionWrapper>
        <h4>
          <T id="account_delete.title" />
        </h4>
        <p>
          <T id="account_delete.warning" />
        </p>
        <div className="ls-actions">
          <Button
            basic
            color="red"
            type="button"
            onClick={() => setDeleteConfirm(true)}
          >
            <T id="account_delete.cta" />
          </Button>
        </div>
      </S.FieldsSectionWrapper>
      <DeleteAccountModal open={deleteConfirm} setOpen={setDeleteConfirm} />
    </>
  );
};
