import { T } from "util/Translation";
import * as S from "./styles";
import { useEffect, useState } from "react";
import { useStore } from "structure";

export const Replies = () => {
  const { repliesStore } = useStore();
  const [data, setData] = useState(null);

  useEffect(() => {
    repliesStore.getUsage().then((res) => setData(res));
    // eslint-disable-next-line
  }, []);

  const status =
    data?.percentage_used < 50
      ? "good"
      : data?.percentage_used < 80
      ? "warning"
      : "bad";

  return (
    <>
      <S.CountWrapper>
        {data ? (
          <>
            <h4>
              🚀 <T id="dashboard.home.replies.total" />
            </h4>
            <h2 className={`ls-${status}`}>
              {data?.current_usage}
              <span>/{data.max_limit}</span>
            </h2>
          </>
        ) : (
          <div className="ls-loader"></div>
        )}
      </S.CountWrapper>
      <S.CountWrapper className="ls-colored">
        <h5>
          💬 <T id="dashboard.home.feedback.title" />
        </h5>
        <p>
          <T id="dashboard.home.feedback.text" />{" "}
          <a
            href="https://live.lumopages.com/lumo/lumo-feedback"
            target="_blank"
            rel="noreferrer"
          >
            <T id="dashboard.home.feedback.cta" />
          </a>
        </p>
      </S.CountWrapper>
    </>
  );
};
