import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "structure";
import { toast } from "react-toastify";
import { formatDate, formatSubmissionDateTime } from "util/helpers";
import { useTranslation } from "react-i18next";

import { Button, Pagination, Tab, TabPane } from "semantic-ui-react";
import { Form } from "react-final-form";
import { Loading } from "components/shared/Loading";
import { FieldText } from "ui/FieldText";
import { FieldDropdown } from "ui/FieldDropdown";
import { Icon } from "assets/icons/Icon";
import { AsText, BasicButton, EmptyWrapper } from "ui/common-styles";
import { Confirm } from "ui/Confirm";
import { T } from "util/Translation";

import * as S from "./styles";

export const Submissions = observer(() => {
  const { t } = useTranslation();
  const { repliesStore, projectsStore, viewStore } = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const [confirmReplyDeleteId, setConfirmReplyDeleteId] = useState(null);
  const [indexPage, setIndexPage] = useState(1);
  const [response, setResponse] = useState(null);
  const { replies, paginationMeta, isEmpty } = repliesStore;
  const [allProjects, setAllProjects] = useState(null);
  const [isMessageView, setIsMessageView] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);

  const downloadReply = async (replyId) => {
    setIsLoadingDownload(true);
    await repliesStore.downloadPdfReply(replyId, `lumo_reply_${replyId}.pdf`);
    setIsLoadingDownload(false);
  };

  useEffect(() => {
    viewStore.setBackNavigation(t("navigation.home"), `/`);
    return () => viewStore.resetBackNavigation();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    repliesStore.fetchReplies().then(() => setIsLoading(false));
    projectsStore.fetchAllProjects().then((data) =>
      setAllProjects(
        data.map((item) => ({
          key: item.id,
          value: item.id,
          text: item.name,
        }))
      )
    );
    return () => setIsLoading(true);
    // eslint-disable-next-line
  }, []);

  const handleDeleteReply = async () => {
    await repliesStore.delete(confirmReplyDeleteId);
    toast.success(t("submissions.reply_deleted"));
  };

  const closeMessageView = () => {
    viewStore.setBackNavigation(t("navigation.home"), `/`);
    setIsMessageView(false);
  };

  const openMessageView = () => {
    viewStore.setBackNavigation(t("navigation.replies"), closeMessageView);
    setIsMessageView(true);
  };

  const showResponse = (item) => {
    setResponse(item);
  };

  useEffect(() => {
    if (!replies) return;
    const data = replies[0];
    if (!data) {
      showResponse(null);
      return;
    }
    const response = JSON.parse(data.response);
    showResponse({
      id: data.id,
      data: response,
      meta: {
        projectId: data.project_id,
        projectName: data.project_name,
        country: data.sender_country,
        time: formatDate(data.submission_time),
      },
    });
    // eslint-disable-next-line
  }, [replies]);

  const handleSearch = (data) => repliesStore.fetchReplies(data);

  const handleFilter = (data) => repliesStore.fetchReplies(data);

  const handleExport = (data) =>
    data.project_id
      ? repliesStore.downloadProjectReplies(
          data.project_id,
          `lumo_projectReplies_${data.project_id}.csv`
        )
      : null;

  const panes = [
    {
      menuItem: "Search",
      render: () => (
        <TabPane>
          <Form
            onSubmit={handleSearch}
            render={({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <FieldText
                  label={t("submissions.search_label")}
                  finalFormProps={{ name: "search" }}
                  semanticProps={{
                    placeholder: t("submissions.search_placeholder"),
                    disabled: submitting,
                  }}
                />

                <Button primary type="submit">
                  {t("submissions.search")}
                </Button>
              </form>
            )}
          />
        </TabPane>
      ),
    },
    {
      menuItem: "Filter",
      render: () => (
        <TabPane>
          {" "}
          <Form
            onSubmit={handleFilter}
            render={({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <FieldDropdown
                  label={t("submissions.select_project")}
                  finalFormProps={{ name: "project_ids" }}
                  semanticProps={{
                    placeholder: t("submissions.select_project_placeholder"),
                    disabled: submitting || !allProjects,
                    loading: !allProjects,
                    options: allProjects || [],
                    multiple: true,
                    defaultValue: [],
                  }}
                />
                <Button primary type="submit">
                  {t("submissions.filter")}
                </Button>
              </form>
            )}
          />
        </TabPane>
      ),
    },
    {
      menuItem: "Export",
      render: () => (
        <TabPane>
          <Form
            onSubmit={handleExport}
            render={({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <FieldDropdown
                  label={t("submissions.project_export")}
                  finalFormProps={{ name: "project_id" }}
                  semanticProps={{
                    placeholder: t("submissions.project_export_placeholder"),
                    disabled: submitting || !allProjects,
                    loading: !allProjects,
                    options: allProjects || null,
                  }}
                />
                <Button
                  primary
                  type="submit"
                  loading={submitting}
                  disabled={submitting}
                >
                  {t("submissions.export")}
                </Button>
              </form>
            )}
          />
        </TabPane>
      ),
    },
  ];

  return (
    <S.Wrapper>
      {isMessageView && (
        <Button
          basic
          className="ls-with-icon ls-nav-back"
          onClick={closeMessageView}
        >
          <Icon name="left" color="var(--grey-600)" />
          <T id={"submissions.title"} />
        </Button>
      )}

      <div className="main-section">
        <div className="header">
          <h1>
            <T id="submissions.title" />
          </h1>
          <p>
            <T id="submissions.intro" />
          </p>
        </div>
      </div>

      <div className="ls-content">
        <S.PanelWrapper>
          <div className="ls-replies">
            <div className="ls-filters">
              <Tab panes={panes} menu={{ secondary: true }} />
            </div>
            {isLoading ? (
              <div style={{ height: 400 }}>
                <Loading />
              </div>
            ) : (
              <div className="ls-results">
                {isEmpty ? (
                  <EmptyWrapper>
                    <h4>
                      <T id="submissions.no_replies_yet" />
                    </h4>
                  </EmptyWrapper>
                ) : (
                  <>
                    <ul onClick={openMessageView}>
                      {replies.map((reply) => (
                        <SubmissionItem
                          key={reply.id}
                          data={reply}
                          showResponse={showResponse}
                          isActive={response?.id === reply.id}
                        />
                      ))}
                    </ul>
                    {paginationMeta.last_page && (
                      <Pagination
                        activePage={indexPage}
                        totalPages={paginationMeta.last_page}
                        boundaryRange={0}
                        siblingRange={1}
                        ellipsisItem={null}
                        firstItem={null}
                        lastItem={null}
                        prevItem={{
                          content: <Icon name="left" />,
                          icon: true,
                        }}
                        nextItem={{
                          content: <Icon name="right" />,
                          icon: true,
                        }}
                        onPageChange={(e, data) => {
                          setIndexPage(data.activePage);
                          repliesStore.fetchReplies({
                            page: data.activePage,
                          });
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            )}
          </div>

          <S.SidebarContent
            className={`ls-dynamic-view${
              isMessageView ? " ls-active-view" : ""
            }`}
          >
            {!response ? null : (
              <ul className="ls-form-response" key={response.id}>
                <li className="project-reply-title">
                  <h3>
                    <T id="submissions.project_name" />{" "}
                    {response.meta.projectName}
                    <AsText
                      type="button"
                      onClick={() => downloadReply(response.id)}
                      disabled={isLoadingDownload}
                      loading={isLoadingDownload}
                    >
                      <Icon name="downloadFile" />
                    </AsText>
                  </h3>
                  <ul className="project-reply-meta">
                    <li>
                      <T id="submissions.project_id" />{" "}
                      <span>{response.meta.projectId}</span>
                    </li>
                    <li>
                      <T id="submissions.submitted_at" />{" "}
                      <span>{response.meta.time}</span>
                    </li>
                    <li>
                      <T id="submissions.submitted_from" />{" "}
                      <span>{response.meta.country}</span>
                    </li>
                  </ul>
                </li>
                {response.data.map((form, idx) => (
                  <li key={idx}>
                    <h6>{form.name}</h6>
                    <ul className="project-reply-content">
                      {form.fields.map((field, s_idx) => (
                        <li key={s_idx}>
                          <span>{field.field}</span>
                          <span>{field.value}</span>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            )}
          </S.SidebarContent>
        </S.PanelWrapper>
      </div>

      <Confirm
        title={t("submissions.delete_confirm_title")}
        text={t("submissions.delete_confirm_text")}
        confirmButton={t("submissions.delete_confirm_button")}
        color="red"
        open={!!confirmReplyDeleteId}
        onCancel={() => setConfirmReplyDeleteId(null)}
        onConfirm={handleDeleteReply}
      />
    </S.Wrapper>
  );
});

const SubmissionItem = ({ data, showResponse, isActive }) => {
  const response = JSON.parse(data.response);
  const replyName = response[0].fields.find((field) =>
    ["name", "first name", "full name"].includes(field.field.toLowerCase())
  );
  const submissionTime = formatSubmissionDateTime(data.submission_time);

  return (
    <S.SubmissionItemWrapper className={isActive ? "ls-active" : ""}>
      <BasicButton
        onClick={() =>
          showResponse({
            id: data.id,
            data: response,
            meta: {
              projectId: data.project_id,
              projectName: data.project_name,
              country: data.sender_country,
              time: formatDate(data.submission_time),
            },
          })
        }
      >
        <div>
          <h5>
            {replyName?.value ? (
              replyName.value
            ) : (
              <T id="submissions.new_reply" />
            )}
          </h5>
        </div>

        <div className="ls-details">
          <span>{data.project_name}</span>
          <span>{submissionTime}</span>
        </div>
      </BasicButton>
    </S.SubmissionItemWrapper>
  );
};
