import { observer } from "mobx-react-lite";

import { useStore } from "structure";
import { Button, Dropdown } from "semantic-ui-react";
import { Icon } from "assets/icons/Icon";

import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { DuplicateFormModal } from "components/Dashboard/CreateForm/Interface/DuplicateFormModal";
import { Confirm } from "ui/Confirm";
import { ProjectContext } from "components/Dashboard/Project/Project";
import { toast } from "react-toastify";
import { BUILD_PARTS } from "util/consts";
import { Link } from "react-router-dom";

export const MenuActions = observer(
  ({ step, idx, stage, isVisible, projectId, lcoalStore }) => {
    const { accountStore, projectsStore } = useStore();
    const { currentAccount } = accountStore;
    const { t } = useTranslation();
    const [modalOpen, setModalOpen] = useState(null);
    const project = useContext(ProjectContext);

    const saveOrderHandler = async () => {
      try {
        await projectsStore.saveFormsOrder(project, lcoalStore.order);
        toast.success(t("project_steps_notification.save_success"));
      } catch {
        toast.error(t("project_steps_notification.save_error"));
      }
    };

    const handleDelete = async () => {
      await projectsStore.deleteForm(project, step.id).then((success) => {
        if (success) {
          lcoalStore.setOrder(
            project?.settings?.order.filter((item) => item.form.id !== step.id)
          );
          toast.success(
            t("interface.deleted_successfully", {
              part: t(
                BUILD_PARTS[step.id]?.name || "build_parts.form_name"
              ).toLowerCase(),
            })
          );
        }
      });
    };

    const handleVisiblity = (isVisible) => {
      lcoalStore.setVisibility(idx, isVisible);
      saveOrderHandler();
    };

    // eslint-disable-next-line
    const handleChangeLocation = async () => {
      lcoalStore.setVisibility(idx, !isVisible);
    };

    return (
      <>
        <Dropdown
          trigger={
            <Button className="ls-bright ls-icon" color="grey">
              <Icon name="menu" />
            </Button>
          }
          disabled={currentAccount?.isLoading}
          floating
          direction="left"
          icon={null}
        >
          <Dropdown.Menu>
            <Dropdown.Item
              as={Link}
              text={t("formStep.menuActions.edit")}
              icon={<Icon name="edit" height={20} />}
              to={`/project/${projectId}/form/${step.id}`}
            />
            {isVisible ? (
              <Dropdown.Item
                text={t("formStep.menuActions.hide")}
                icon={<Icon name="unpublished" height={20} />}
                onClick={() => setModalOpen("hide")}
              />
            ) : (
              <Dropdown.Item
                text={t("formStep.menuActions.visible")}
                icon={<Icon name="published" height={20} />}
                onClick={() => setModalOpen("visible")}
              />
            )}

            <Dropdown.Item
              text={t("formStep.menuActions.duplicate")}
              icon={<Icon name="duplicate" height={20} />}
              onClick={() => setModalOpen("duplicate")}
            />
            <Dropdown.Item
              text={t("formStep.menuActions.delete")}
              icon={<Icon name="delete" height={20} />}
              onClick={() => setModalOpen("delete")}
            />
          </Dropdown.Menu>
        </Dropdown>

        <Confirm
          title={t("interface.hide_step_title")}
          text={t("interface.hide_step_text")}
          confirmButton={t("interface.confirm_hide")}
          color="red"
          open={modalOpen === "hide"}
          onCancel={() => setModalOpen(null)}
          onConfirm={() => handleVisiblity(false)}
        />

        <Confirm
          title={t("interface.visible_step_title")}
          text={t("interface.visible_step_text")}
          confirmButton={t("interface.confirm_visible")}
          color="green"
          open={modalOpen === "visible"}
          onCancel={() => setModalOpen(null)}
          onConfirm={() => handleVisiblity(true)}
        />

        <Confirm
          title={t("interface.delete_step_title")}
          text={t("interface.delete_step_text")}
          confirmButton={t("interface.confirm_delete")}
          color="red"
          open={modalOpen === "delete"}
          onCancel={() => setModalOpen(null)}
          onConfirm={handleDelete}
        />

        <DuplicateFormModal
          open={modalOpen === "duplicate"}
          setOpen={(bool) =>
            bool ? setModalOpen("duplicate") : setModalOpen(null)
          }
          projectId={projectId}
          sourceForm={step}
        />
      </>
    );
  }
);
