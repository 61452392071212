import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  h3 {
    font-family: var(--title-font);
    color: var(--primary);
    font-weight: 700;
    font-size: 64px;
  }

  > .ls-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 48px;
    min-height: 168px;
    background: linear-gradient(0, var(--primary-700), var(--primary-500));
    border-radius: 8px;
    width: 100%;
    color: var(--white);
  }

  .ls-body {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto auto;
    gap: 16px;

    > div {
      background: var(--grey-50);
      border: 1px solid var(--grey-200);
      padding: 24px;
      border-radius: 8px;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      animation: fadeIn 0.3s;

      @media only screen and (min-width: 800px) {
        :nth-child(1) {
          grid-column: 1 / span 12;
          grid-row: 1;
        }

        :nth-child(2) {
          grid-column: 1 / span 5;
          grid-row: 2;
        }

        :nth-child(3) {
          grid-column: 6 / span 7;
          grid-row: 2;
        }

        :nth-child(4) {
          grid-column: 1 / span 12;
          grid-row: 3;
        }
      }

      @media only screen and (min-width: 1100px) {
        :nth-child(1) {
          grid-column: 1 / span 8;
          grid-row: 1;
        }

        :nth-child(2) {
          grid-column: 1 / span 3;
          grid-row: 2;
        }

        :nth-child(3) {
          grid-column: 4 / span 5;
          grid-row: 2;
        }

        :nth-child(4) {
          grid-column: 9 / span 4;
          grid-row: 1 / span 2;
        }
      }

      @media only screen and (max-width: 800px) {
        :nth-child(1) {
          grid-column: 1 / span 12;
          grid-row: 1;
        }

        :nth-child(2) {
          grid-column: 1 / span 12;
          grid-row: 2;
        }

        :nth-child(3) {
          grid-column: 1 / span 12;
          grid-row: 4;
        }

        :nth-child(4) {
          grid-column: 1 / span 12;
          grid-row: 3;
        }
      }
    }
  }
`;

export const ProjectSectionWrapper = styled.div`
  width: 100%;
  min-width: min(512px, 100%);
  animation: fadeIn 0.3s;

  h2 {
    color: var(--secondary-600);
    line-height: 1.5;
  }

  .ls-projects {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    width: 100%;
    gap: 24px;
    margin-bottom: 8px;
  }

  .ls-more-projects {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    a {
      font-family: var(--title-font);
      color: var(--secondary-600);
      display: flex;
      align-items: center;
      text-decoration: none;

      svg {
        fill: var(--secondary-600);
        margin-bottom: -2px;
      }
    }
  }

  .ls-public,
  .ls-draft {
    padding: 4px 16px;
    border-radius: 4px;
    line-height: 1.5;
  }

  .ls-public {
    color: var(--green-700);
    background: var(--green-100);
  }

  .ls-draft {
    color: var(--grey-700);
    background: var(--grey-100);
  }
`;

export const TipsSectionWrapper = styled.div`
  width: 100%;
  min-width: 200px;

  .ls-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > .ui.button {
      margin-top: 40px;
    }
  }

  .ls-article {
    display: flex;
    flex-direction: column;
    gap: 4px;

    h6 {
      color: var(--secondary-600);
    }

    p {
      color: var(--grey-500);
      margin-bottom: 0;
    }

    .ui.button {
      padding: 4px 4px 4px 12px;
    }
  }
`;

export const CountWrapper = styled.div`
  &.ls-colored.ls-colored {
    background: var(--tertiary-50);
    border-color: var(--tertiary-500);

    a {
      color: var(--tertiary-800);
      display: inline-block;
    }
  }

  gap: 8px !important;
  justify-content: center !important;

  &.ls-center {
    align-items: center !important;
    text-align: center;
  }

  h2 {
    font-size: 48px;
  }

  h3 {
    font-size: 32px;

    &.ls-good {
      color: var(--green-500);
    }
    &.ls-warning {
      color: var(--orange-500);
    }
    &.ls-bad {
      color: var(--red-500);
    }

    span {
      font-size: 22px;
    }
  }

  p {
    color: var(--grey-600);
  }
`;
