import { useTranslation } from "react-i18next";
import StyleFields from "./StyleFields";
import { Accordion } from "semantic-ui-react";

const GlobalSettings = () => {
  const { t } = useTranslation();

  const sectionPanels = [
    {
      key: "part-1",
      title: t("projectPreview.design.global.section.theme"),
      content: {
        content: <PartTheme />,
      },
    },
    {
      key: "part-2",
      title: t("projectPreview.design.global.section.title"),
      content: {
        content: <PartTitle />,
      },
    },
    {
      key: "part-3",
      title: t("projectPreview.design.global.section.text"),
      content: {
        content: <PartText />,
      },
    },
    {
      key: "part-4",
      title: t("projectPreview.design.global.section.background"),
      content: {
        content: <PartBackground />,
      },
    },
  ];

  return (
    <>
      <Accordion defaultActiveIndex={0} panels={sectionPanels} styled />
    </>
  );
};

const PartTheme = () => (
  <>
    <StyleFields.Switch
      name="global.steps"
      labelIntl="projectPreview.design.global.steps"
    />
    <StyleFields.Switch
      name="global.titles"
      labelIntl="projectPreview.design.global.showTitles"
    />
    <StyleFields.Color
      name="global.primaryColor"
      labelIntl="projectPreview.design.global.primary_color"
    />
  </>
);
const PartBackground = () => (
  <>
    <StyleFields.Background
      name="global.background"
      labelIntl="projectPreview.design.global.background"
    />
  </>
);

const PartTitle = () => (
  <>
    <StyleFields.FontStyle
      name="global.titleStyle"
      labelIntl="projectPreview.design.global.title_style"
    />
    <StyleFields.FontSize
      name="global.titleSize"
      labelIntl="projectPreview.design.global.title_style"
      //defaultValue={{ size: 24, weight: "700" }}
    />
    <StyleFields.Allignment
      name="global.titleAlign"
      labelIntl="projectPreview.design.input.align"
    />
  </>
);
const PartText = () => (
  <>
    <StyleFields.FontStyle
      name="global.textStyle"
      labelIntl="projectPreview.design.global.text_style"
    />
    <StyleFields.FontSize
      name="global.textSize"
      labelIntl="projectPreview.design.global.text_size"
      //defaultValue={{ size: 16, weight: "400" }}
    />
    <StyleFields.Allignment
      name="global.textAlign"
      labelIntl="projectPreview.design.input.align"
    />
  </>
);

export default GlobalSettings;
