import { observer } from "mobx-react-lite";
import { useRef } from "react";

import { Accordion } from "semantic-ui-react";
import ButtonSettings from "./settingsSections/ButtonSettings";
import InputSettings from "./settingsSections/InputSettings";
import FormFrameSettings from "./settingsSections/FormFrameSettings";
import GlobalSettings from "./settingsSections/GlobalSettings";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { T } from "util/Translation";

export const DesignEditor = observer(({ setCustomUi, initialValues }) => {
  const { t } = useTranslation();

  const debounceData = useRef({
    pending: false,
    lastValues: null,
  });

  const onSubmit = () => null;

  const rootPanels = [
    {
      key: "panel-1",
      title: t("projectPreview.design.global.title"),
      content: {
        content: <GlobalSettings />,
      },
    },
    {
      key: "panel-2",
      title: t("projectPreview.design.formFrame.title"),
      content: {
        content: <FormFrameSettings />,
      },
    },
    {
      key: "panel-3",
      title: t("projectPreview.design.input.title"),
      content: {
        content: <InputSettings />,
      },
    },
    {
      key: "panel-4",
      title: t("projectPreview.design.button.title"),
      content: {
        content: <ButtonSettings />,
      },
    },
  ];

  const throttledSetCustomUi = (values) => {
    if (debounceData.current.pending) {
      debounceData.current.lastValues = values;
    } else {
      debounceData.current.pending = true;
      setCustomUi(values || {});
      setTimeout(() => {
        debounceData.current.pending = false;
        if (debounceData.current.lastValues) {
          const restoreValues = debounceData.current.lastValues;
          debounceData.current.lastValues = null;
          throttledSetCustomUi(restoreValues);
        }
      }, 320);
    }
  };

  return (
    <div>
      <div className="ls-section-header">
        <h5>
          <T id="projectPreview.design.title" />
        </h5>
        <p>
          <T id="projectPreview.design.desc" />
        </p>
      </div>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        subscription={{}}
        render={({ handleSubmit, form }) => {
          form.subscribe((state) => throttledSetCustomUi(state.values), {
            values: true,
          });

          return (
            <form onSubmit={handleSubmit}>
              <Accordion defaultActiveIndex={0} panels={rootPanels} styled />
            </form>
          );
        }}
      />
    </div>
  );
});
