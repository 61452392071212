import { observer } from "mobx-react-lite";
import { useStore } from "structure";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Loading } from "components/shared/Loading";
import { PersonalDetails } from "./sections/PersonalDetails";
import { Avatar } from "./sections/Avatar";
import { Security } from "./sections/Security";
import { Billing } from "./sections/Billing";

import { T } from "util/Translation";

import * as S from "./styles";
import { DeleteAccount } from "./sections/DeleteAccount";

export const Account = observer(() => {
  const { accountStore, viewStore } = useStore();
  const { t } = useTranslation();
  const { isLoading } = accountStore;

  useEffect(() => {
    viewStore.setBackNavigation(t("navigation.home"), `/`);
    return () => viewStore.resetBackNavigation();
    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return (
      <div style={{ height: 400 }}>
        <Loading />
      </div>
    );
  }

  return (
    <S.Wrapper>
      <div className="main-section">
        <div className="header">
          <h1>
            <T id="account.title" />
          </h1>
          <p>
            <T id="account.intro" />
          </p>
        </div>
      </div>
      <div className="ls-content">
        <S.SectionWrapper>
          <PersonalDetails />
          <Avatar />
        </S.SectionWrapper>

        <S.SectionWrapper>
          <Security />
        </S.SectionWrapper>

        <S.SectionWrapper>
          <Billing />
        </S.SectionWrapper>

        <S.SectionWrapper>
          <DeleteAccount />
        </S.SectionWrapper>
      </div>
    </S.Wrapper>
  );
});
