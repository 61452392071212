import styled from "styled-components";

export const HeaderWrapper = styled.header`
  width: 100%;
  height: 54px;
  background: var(--grey-50);
  border-bottom: 1px solid var(--grey-200);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 24px;
  z-index: 1000;
  box-shadow: 0px 4px 8px #1d283a08;

  button svg {
    fill: var(--grey-700);
  }

  @media only screen and (min-width: 700px) {
    display: none;
  }
`;

export const BackNavigationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  height: 38px;

  .ui.button {
    margin-left: -8px;
    padding: 8px;
    text-decoration: none;
    font-weight: 700;
    font-size: 16px;
    color: var(--grey-500) !important;
    animation: fadeIn 0.3s;

    :active,
    :hover,
    :focus {
      color: var(--grey-500) !important;
    }

    svg {
      height: 20px;
      width: auto;
      fill: var(--grey-500);
    }

    &.ls-logo svg {
      height: 26px;
      margin-top: 2px;
    }
  }
`;

export const MobileNavigationWrapper = styled.div`
  position: fixed;
  top: 54px;
  left: 100%;
  width: 100%;
  height: calc(100dvh - 54px);
  background: var(--secondary-50);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 24px 48px;
  z-index: 999999;
  transition: 0.3s;
`;

export const MobileOverlay = styled.div`
  position: fixed;
  top: 54px;
  left: 0;
  width: 100%;
  height: calc(100dvh - 54px);
  background: var(--grey-900);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 24px 48px;
  z-index: 999999;
  opacity: 0.8;
  animation: lightOut 0.3s forwards;

  @keyframes lightOut {
    from {
      opacity: 0;
    }

    to {
      opacity: 0.8;
    }
  }
`;

export const BoxWrapper = styled.div`
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  &.ls-open {
    .ls-header {
      > svg {
        transform: rotate(-90deg);
      }
      > img,
      > h3 svg,
      .ls-avatar {
        border: 1px solid var(--grey-700);
      }
    }
  }

  &.ls-close {
    .ls-header > svg {
      transform: rotate(90deg);
    }

    .ls-body {
      max-height: 0;
      padding: 0 8px;
      overflow: hidden;
      > * {
        opacity: 0;
      }
    }
  }

  .ui.button.ls-header {
    padding: 8px 24px;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.3s;

    h3 {
      font-size: 18px;
      font-weight: 500;
      color: var(--grey-700);
      display: flex;
      align-items: center;
      gap: 12px;
      > svg {
        padding: 6px;
        border: 1px solid var(--grey-300);
        border-radius: 100%;
        height: 36px;
        width: 36px;
        fill: var(--grey-600);
      }
      > img,
      .ls-avatar {
        height: 36px;
        width: 36px;
        font-size: 14px;
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 400;
        background: var(--secondary-500);
        color: var(--secondary-50);
        object-fit: cover;
      }
    }

    > svg {
      fill: var(--grey-700);
    }
  }

  .ls-body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding: 8px;
    max-height: 100vh;
    transition: 0.3s;
    transition-timing-function: ease-in-out;

    > a,
    > .ui.button {
      display: flex;
      padding: 12px 16px 12px 32px;
      font-weight: 400;
      width: 100%;
      border-bottom: 1px solid var(--secondary-100);
      border-radius: 0;
      justify-content: space-between;
      text-decoration: none;

      transition: 0.2s;
      transition-timing-function: ease-in-out;

      p {
        font-size: 18px;
        line-height: 24px;
        display: flex;
        align-items: center;
        gap: 8px;
        margin: 0;
        color: var(--grey-600);
      }

      svg {
        fill: var(--grey-500);
      }

      :last-child {
        border: none;
      }
    }
  }
`;
