import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .ls-content {
    margin: 16px auto;
    width: calc(100% - 32px);
    max-width: 800px;
  }
`;

export const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 64px;
  margin-bottom: 40px;

  @media only screen and (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`;
