import { makeObservable, observable, runInAction, action } from "mobx";
import { projects as apiProjects } from "../endpoints";
import { makeid } from "util/helpers";

export class Projects {
  projects = [];
  isLoading = false;
  currentTab = 0;
  panelTemplateIndex = 0;

  constructor(root) {
    this.rootStore = root;
    makeObservable(this, {
      projects: observable.ref,
      isLoading: observable,
      currentTab: observable,
      panelTemplateIndex: observable,
      setCurrentTab: action,
      setPanelTemplateIndex: action,
    });
  }

  setCurrentTab(index) {
    this.currentTab = index;
    this.panelTemplateIndex = 0;
  }

  setPanelTemplateIndex(index) {
    this.panelTemplateIndex = index;
  }

  async fetchProjects(params) {
    runInAction(() => {
      this.isLoading = true;
    });
    const res = await apiProjects.get(params);
    if (res.data) {
      runInAction(() => {
        this.projects = res.data.map((project) => ({
          ...project,
          forms: JSON.parse(project.forms),
          settings: JSON.parse(project.settings),
          public_settings: JSON.parse(project.public_settings),
        }));
        this.isLoading = false;
      });
    }

    runInAction(() => {
      this.isLoading = false;
    });
  }

  async getById(projectId) {
    const res = await apiProjects.getById(projectId);
    return this.parseProject(res?.data);
  }

  parseProject(project) {
    if (project) {
      return {
        ...project,
        forms: JSON.parse(project.forms),
        settings: JSON.parse(project.settings),
        public_settings: JSON.parse(project.public_settings),
      };
    }
    return null;
  }

  findProjectById(projectId) {
    return this.projects?.find((project) => project.id === projectId);
  }

  getProjectNameById(projectId) {
    return this.findProjectById(projectId)?.name || "unknown";
  }

  findForm(projectId, formId) {
    return (
      this.findProjectById(projectId)?.settings?.order?.find(
        (element) => element.form.id === formId
      )?.form || null
    );
  }

  async createNewForm(projectId, name) {
    const project = await this.getById(projectId);

    if (project) {
      const order = project.settings?.order || [];
      const newId = makeid(16);
      const newForm = {
        id: newId,
        name: name ?? "Untitled Form",
        created_at: new Date(),
        updated_at: new Date(),
      };
      const newOrder = [...order, { form: newForm, isVisible: false }];
      await this.saveFormsOrder(project, newOrder);
      return newId;
    }
    return null;
  }

  async duplicateForm(projectId, data) {
    const project =
      this.findProjectById(projectId) || (await this.getById(projectId));

    if (project) {
      const order = project.settings?.order || [];
      const newId = makeid(16);
      const newForm = {
        ...data,
        id: newId,
        created_at: new Date(),
        updated_at: new Date(),
      };

      const newOrder = [...order, { form: newForm, isVisible: false }];
      await this.saveFormsOrder(project, newOrder);
      await this.fetchProjects();
      return newId;
    }
    return null;
  }

  async saveForm(project, formId, formFields, isPublic = false) {
    const order = project.settings?.order || [];

    const formIndex = order?.findIndex((element) => element.form.id === formId);

    if (project && formIndex > -1) {
      const newForm = { ...formFields, updated_at: new Date() };
      const newOrder = [
        ...order.slice(0, formIndex),
        {
          form: newForm,
          isVisible: isPublic,
        },
        ...order.slice(formIndex + 1),
      ];
      await this.saveFormsOrder(project, newOrder);
      return newForm;
    }
    return null;
  }

  async deleteForm(project, formId) {
    const order = project.settings?.order || [];
    const formIndex = order?.findIndex((element) => element.form.id === formId);
    if (project && formIndex > -1) {
      const newOrder = [
        ...order.slice(0, formIndex),
        ...order.slice(formIndex + 1),
      ];
      await this.saveFormsOrder(project, newOrder);
      return true;
    }
    return false;
  }

  saveFormsOrder(project, order) {
    const currentSettings = project.settings || {};
    let newProjectForms = [];

    order.forEach((element) => {
      if (element.isVisible) newProjectForms.push(element.form);
    });

    return this.update(project.id, {
      forms: newProjectForms,
      settings: JSON.stringify({ ...currentSettings, order: order }),
    });
  }

  saveBuildContent(project, newSettings) {
    const currentSettings = project.settings || {};
    const currentPublicSettings = project.public_settings || {};
    let newPublicSettings = {};
    Object.entries(newSettings).forEach(([field, data]) => {
      if (data?.isActive) {
        newPublicSettings[field] = data.content;
      }
    });

    const res = this.update(project.id, {
      settings: JSON.stringify({ ...currentSettings, ...newSettings }),
      public_settings: JSON.stringify({
        ...currentPublicSettings,
        ...newPublicSettings,
      }),
    });
    this.fetchProjects();
    return res;
  }

  async saveProjectSettings(
    projectId,
    projectSettingsData = {},
    projectPublicSettingsData = {}
  ) {
    const project = await this.getById(projectId);
    if (project.id !== projectId) return null;

    const currentSettings = project?.settings || {};
    const currentPublicSettings = project?.public_settings || {};

    return this.update(projectId, {
      settings: JSON.stringify({ ...currentSettings, ...projectSettingsData }),
      public_settings: JSON.stringify({
        ...currentPublicSettings,
        ...projectPublicSettingsData,
      }),
    });
  }

  async create(name) {
    const direction =
      this.rootStore.getCurrentLanguage() === "he" ? "RTL" : "LTR";
    const res = await apiProjects.create({
      name,
      public: 0,
      slug: makeid(),
      public_settings: JSON.stringify({
        template: "default",
        direction: direction,
      }),
      settings: JSON.stringify({ order: [] }),
    });
    await this.fetchProjects();
    return res;
  }

  async duplicate(data) {
    const res = await apiProjects.create({
      name: data.name,
      public: 0,
      slug: makeid(),
      forms: data.forms,
      public_settings: JSON.stringify(data.public_settings),
      settings: JSON.stringify(data.settings),
    });
    await this.fetchProjects();
    return res?.data?.id;
  }

  async update(id, data) {
    const res = await apiProjects.update(id, data);
    if (res?.status === "error") {
      return false;
    }
    return this.parseProject(res);
  }

  async delete(id) {
    const res = await apiProjects.delete(id);
    await this.fetchProjects();
    return res;
  }

  async fetchAllProjects() {
    return apiProjects.get().then((res) => {
      if (Array.isArray(res.data)) {
        return res.data.map((item) => ({ id: item.id, name: item.name }));
      }
      return [];
    });
  }
}
