import { observer } from "mobx-react-lite";
import { Button, Modal } from "semantic-ui-react";
import { ModalCloseWrapper, ModalContentWrapper } from "ui/common-styles";
import { Icon } from "assets/icons/Icon";
import { T } from "util/Translation";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { BrandIcon } from "assets/icons/BrandIcons";
import { useTranslation } from "react-i18next";

export const ShareModal = observer(
  ({ open, setOpen, projectURL, projectEmbedCode }) => {
    const { t } = useTranslation();

    return (
      <>
        <Modal
          size="tiny"
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          closeIcon={
            <ModalCloseWrapper>
              <Icon name="close" />
            </ModalCloseWrapper>
          }
        >
          <ModalContentWrapper>
            <div className="ls-content">
              <h3>
                <T id="continue_form_modal.title" />
              </h3>
              <p>
                <T id="continue_form_modal.description" />
              </p>
            </div>
            <div className="ls-options">
              <Button
                className="ls-bright"
                color="grey"
                onClick={() => {
                  navigator.clipboard
                    .writeText(projectURL)
                    .then(() => {
                      toast.success(t("notification.copy_success"));
                      setOpen(false);
                    })
                    .catch((err) => {
                      toast.error(t("notification.copy_failed"));
                    });
                }}
              >
                <T id="share_form_modal.copy" />
                <Icon name="copy" />
              </Button>

              <Button
                as={Link}
                className="ls-bright"
                color="grey"
                onClick={() => {
                  setOpen(false);
                }}
                to={{
                  pathname: `https://www.facebook.com/sharer/sharer.php?u=${projectURL}`,
                }}
                target="_blank"
                style={{ color: "#0866FF" }}
              >
                <T id="share_form_modal.fb" />
                <BrandIcon name="facebook" color="#0866FF" />
              </Button>
              <Button
                as={Link}
                className="ls-bright"
                color="grey"
                onClick={() => {
                  setOpen(false);
                }}
                to={{
                  pathname: `https://www.linkedin.com/sharing/share-offsite/?url=${projectURL}`,
                }}
                target="_blank"
                style={{ color: "#0a66c2" }}
              >
                <T id="share_form_modal.li" />
                <BrandIcon name="linkedin" color="#0a66c2" />
              </Button>
              <Button
                as={Link}
                className="ls-bright"
                color="grey"
                onClick={() => {
                  setOpen(false);
                }}
                to={{ pathname: `https://wa.me/?text=${projectURL}` }}
                target="_blank"
                style={{ color: "#25D366" }}
              >
                <T id="share_form_modal.wa" />
                <BrandIcon name="whatsapp" color="#25D366" />
              </Button>
              <Button
                as={Link}
                className="ls-bright"
                color="grey"
                onClick={() => {
                  setOpen(false);
                }}
                to={{
                  pathname: `https://t.me/share/url?url=${projectURL}&text=Check this out!`,
                }}
                target="_blank"
                style={{ color: "#26A5E4" }}
              >
                <T id="share_form_modal.tg" />
                <BrandIcon name="telegram" color="#26A5E4" />
              </Button>
              <Button
                className="ls-bright"
                secondary
                onClick={() => {
                  navigator.clipboard
                    .writeText(projectEmbedCode)
                    .then(() => {
                      toast.success(t("notification.copy_success"));
                      setOpen(false);
                    })
                    .catch((err) => {
                      toast.error(t("notification.copy_failed"));
                    });
                }}
              >
                <T id="share_form_modal.embed" />
                <Icon name="code" />
              </Button>
            </div>
          </ModalContentWrapper>
        </Modal>
      </>
    );
  }
);
