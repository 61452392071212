import { useTranslation } from "react-i18next";
import StyleFields from "./StyleFields";
import { Accordion } from "semantic-ui-react";

const InputSettings = () => {
  const { t } = useTranslation();

  const sectionPanels = [
    {
      key: "part-1",
      title: t("projectPreview.design.input.section.field"),
      content: {
        content: <PartField />,
      },
    },
    {
      key: "part-2",
      title: t("projectPreview.design.input.section.conteiner"),
      content: {
        content: <PartConteiner />,
      },
    },
    {
      key: "part-3",
      title: t("projectPreview.design.input.section.border"),
      content: {
        content: <PartBorder />,
      },
    },
  ];

  return (
    <>
      <Accordion defaultActiveIndex={0} panels={sectionPanels} styled />
    </>
  );
};

const PartConteiner = () => (
  <>
    <StyleFields.Allignment
      name="input.alignment"
      labelIntl="projectPreview.design.input.align"
    />
    <StyleFields.Width
      name="input.width"
      labelIntl="projectPreview.design.input.width"
      smallSize={200}
    />
  </>
);
const PartField = () => (
  <>
    <StyleFields.Switch
      name="input.mark_require"
      labelIntl="projectPreview.design.input.mark_require"
    />
  </>
);

const PartBorder = () => (
  <>
    <StyleFields.Border
      name="input.border"
      labelIntl="projectPreview.design.input.border"
    />
    <StyleFields.Radius
      name="input.radius"
      labelIntl="projectPreview.design.input.radius"
    />
    <StyleFields.Shadow
      name="input.shadow"
      labelIntl="projectPreview.design.input.shadow"
    />
  </>
);

export default InputSettings;
