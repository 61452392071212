import { useTranslation } from "react-i18next";
import StyleFields from "./StyleFields";
import { Accordion } from "semantic-ui-react";

const FormFrameSettings = () => {
  const { t } = useTranslation();

  const sectionPanels = [
    {
      key: "part-1",
      title: t("projectPreview.design.form_frame.section.conteiner"),
      content: {
        content: <PartConteiner />,
      },
    },
    {
      key: "part-2",
      title: t("projectPreview.design.form_frame.section.background"),
      content: {
        content: <PartBackground />,
      },
    },
    {
      key: "part-3",
      title: t("projectPreview.design.form_frame.section.border"),
      content: {
        content: <PartBorder />,
      },
    },
  ];

  return (
    <>
      <Accordion defaultActiveIndex={0} panels={sectionPanels} styled />
    </>
  );
};

const PartConteiner = () => (
  <>
    <StyleFields.Allignment
      name="formFrame.alignment"
      labelIntl="projectPreview.design.form_frame.position"
      sub
    />
    <StyleFields.Width
      name="formFrame.width"
      labelIntl="projectPreview.design.form_frame.width"
      smallSize={400}
    />
  </>
);
const PartBackground = () => (
  <>
    <StyleFields.Background
      name="formFrame.background"
      labelIntl="projectPreview.design.form_frame.background"
    />
  </>
);

const PartBorder = () => (
  <>
    <StyleFields.Border
      name="formFrame.border"
      labelIntl="projectPreview.design.form_frame.border"
    />
    <StyleFields.Radius
      name="formFrame.radius"
      labelIntl="projectPreview.design.form_frame.radius"
    />
    <StyleFields.Shadow
      name="formFrame.shadow"
      labelIntl="projectPreview.design.form_frame.shadow"
    />
  </>
);

export default FormFrameSettings;
