import { observer } from "mobx-react-lite";
import { T } from "util/Translation";
import { useStore } from "structure";
import { ProjectSection } from "./ProjectsSection";
import { TipsSection } from "./TipsSection";
import { Replies } from "./Replies";
import * as S from "./styles";

export const Home = observer(() => {
  const { accountStore } = useStore();
  const { currentAccount } = accountStore;

  return (
    <S.Wrapper>
      <div className="ls-header">
        <h1>
          <T id="welcome" values={{ name: currentAccount.first_name }} />
        </h1>
      </div>
      <div className="ls-body">
        <S.ProjectSectionWrapper>
          <ProjectSection />
        </S.ProjectSectionWrapper>

        <Replies />
        <S.TipsSectionWrapper>
          <TipsSection />
        </S.TipsSectionWrapper>
      </div>
    </S.Wrapper>
  );
});
