import { FieldImage } from "ui/FieldImage";
import { useTranslation } from "react-i18next";
import { Field, useFormState } from "react-final-form";
import { FieldButtonSelect } from "ui/FieldButtonSelect";
import StyleFields from "components/Dashboard/Project/ProjectPreview/settingsSections/StyleFields";
import { Link } from "react-router-dom";
import { useState } from "react";
import { MenuItem, Tab } from "semantic-ui-react";
import { T } from "util/Translation";
import { AsText } from "ui/common-styles";
import { Icon } from "assets/icons/Icon";
import ReactQuill from "react-quill";
import { LinksSettings } from "./LinksSettings";
import * as S from "./styles";

export const FooterEditor = ({ handleSubmit }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const { values } = useFormState();

  const handleTabChange = (e, { activeIndex }) => {
    setCurrentTab(activeIndex);
  };

  const panes = [
    {
      menuItem: (
        <MenuItem key="frame">
          <T id="Footer section" />
        </MenuItem>
      ),
      render: () => <FrameSettings />,
    },
    {
      menuItem: (
        <MenuItem key="about">
          <T id="About" />
        </MenuItem>
      ),
      render: () => <AboutSettings />,
    },
    {
      menuItem: (
        <MenuItem key="links1">
          <T id="Links #1" />
        </MenuItem>
      ),
      render: () => <LinksSettings key="links1" name="links1" />,
    },
    {
      menuItem: (
        <MenuItem key="links2">
          <T id="Links #2" />
        </MenuItem>
      ),
      render: () => <LinksSettings key="links2" name="links2" />,
    },
    {
      menuItem: (
        <MenuItem key="links3">
          <T id="Links #3" />
        </MenuItem>
      ),
      render: () => <LinksSettings key="links3" name="links3" />,
    },
  ];

  return (
    <S.ContainerForm onSubmit={handleSubmit}>
      <S.DemoBodyWrapper>
        <div>
          <h5>Preview</h5>
          <S.FooterPreviewWrapper frame={values.frame}>
            <About data={values.about} onClick={() => setCurrentTab(1)} />
            <div className="ls-links">
              <Links data={values.links1} onClick={() => setCurrentTab(2)} />
              <Links data={values.links2} onClick={() => setCurrentTab(3)} />
              <Links data={values.links3} onClick={() => setCurrentTab(4)} />
            </div>
          </S.FooterPreviewWrapper>
        </div>
      </S.DemoBodyWrapper>

      <h5 className="ls-hide-s">Settings</h5>

      <Tab
        className="ls-settings"
        menu={{ fluid: true, vertical: true }}
        panes={panes}
        activeIndex={currentTab}
        onTabChange={handleTabChange}
      />
    </S.ContainerForm>
  );
};

const sizeOptions = [
  {
    key: 1,
    value: 32,
    content: "Small",
  },
  {
    key: 2,
    value: 48,
    content: "Medium",
  },
  {
    key: 3,
    value: 64,
    content: "Large",
  },
];

const About = ({ data, onClick }) => {
  if (!data || !(data.title || data.logo || data.text)) {
    return <S.Placeholder onClick={onClick}>About</S.Placeholder>;
  }
  return (
    <div className="ls-part-wrapper ls-about">
      <div>
        <S.Title size={data.size || 40}>
          {data.logo?.[0] ? (
            <img src={data.logo[0]} alt={data.title || "logo"} />
          ) : (
            <h4>{data.title}</h4>
          )}
        </S.Title>
        <S.RichText dangerouslySetInnerHTML={{ __html: data.text }} />
      </div>

      <AsText className="ls-edit-icon" onClick={onClick}>
        <Icon name="edit" />
      </AsText>
    </div>
  );
};

const Links = ({ data, onClick }) => {
  if (
    !data ||
    !(
      data?.title ||
      data?.linkIds?.some(
        (id) => data?.linksContent?.[id] || data?.linksContent?.[id]?.text
      )
    )
  ) {
    return <S.Placeholder onClick={onClick}>Links column</S.Placeholder>;
  }
  const links =
    (data?.linkIds || [])
      .map((id) => ({ id: id, ...data.linksContent?.[id] }))
      .filter((link) => link?.text) || [];

  return (
    <div className="ls-part-wrapper">
      <ul>
        {data?.title && (
          <li>
            <h6>{data.title}</h6>
          </li>
        )}
        {links.map((link) => (
          <li key={link.id}>
            <Link href={link?.link} target="_blank">
              {link?.text}
            </Link>
          </li>
        ))}
      </ul>
      <AsText className="ls-edit-icon" onClick={onClick}>
        <Icon name="edit" />
      </AsText>
    </div>
  );
};

const FrameSettings = () => (
  <div className="ls-group">
    <StyleFields.Size
      sub
      name="frame.height"
      labelIntl="Footer minimal height"
    />
    <StyleFields.Color name="frame.text" labelIntl="Header text color" />
    <StyleFields.Background
      name="frame.background"
      sub
      labelIntl="Header background"
    />
    <StyleFields.Color name="frame.border" labelIntl="Header border color" />
  </div>
);

const AboutSettings = () => {
  const { t } = useTranslation();
  return (
    <div className="ls-group">
      <StyleFields.Text sub name="about.title" labelIntl="Title text" />
      <FieldImage
        label={t("Logo")}
        finalFormProps={{
          name: "about.logo",
        }}
      />
      <FieldButtonSelect
        label={t("Title size")}
        finalFormProps={{
          name: "about.size",
          defaultValue: 40,
        }}
        options={sizeOptions}
        columns={3}
      />
      <Field name={`about.text`}>
        {(props) => (
          <ReactQuill
            value={props.input.value}
            onChange={(value) => {
              props.input.onChange(value);
            }}
            modules={{
              toolbar: toolbarOptions,
            }}
            theme="snow"
          />
        )}
      </Field>
    </div>
  );
};

const toolbarOptions = [
  [{ size: ["small", false, "large", "huge"] }],
  ["bold", "italic", "underline"],
  ["link", "blockquote", "code-block"],
  [{ list: "ordered" }, { list: "bullet" }],
  //[{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  //[{ font: [] }],
  [{ align: [] }],
];
