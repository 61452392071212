import styled from "styled-components";
import { scrollElement } from "ui/common-styles";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .main-section {
    padding-bottom: 32px;
  }

  .ls-media-info {
    padding: 24px 0;
    border-top: 1px solid var(--grey-200);

    margin: 16px auto;
    width: calc(100% - 32px);
    max-width: 800px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .ls-bar {
      max-width: 400px;
      width: 100%;
      display: flex;
      height: 8px;
      background: var(--grey-200);
      border-radius: 4px;

      .ls-progress {
        height: 8px;
        background: var(--grey-400);
        border-radius: 4px;
        transition: 0.4s;
      }
    }

    > span:last-child {
      text-align: right;
      max-width: 400px;
      font-size: 14px;
    }
  }
`;

export const GalleryWrapper = styled.div`
  background: white;
  border-radius: 16px;

  input[type="file"] {
    font-family: var(--primary-font);
    margin-right: 20px;
  }

  input[type="file"]::file-selector-button {
    border-radius: 4px;
    padding: 0 16px;
    height: 40px;
    cursor: pointer;
    font-family: var(--primary-font);
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    transition: background-color 200ms;
  }

  /* file upload button hover state */
  input[type="file"]::file-selector-button:hover {
    background-color: #f3f4f6;
  }

  /* file upload button active state */
  input[type="file"]::file-selector-button:active {
    background-color: #e5e7eb;
  }

  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 200px);
    gap: 16px;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: auto;
    margin: 24px auto;
    width: calc(100% - 32px);
    max-width: 800px;

    ${scrollElement}
  }

  .gallery-upload {
    padding: 16px;
    border-top: 1px solid var(--grey-200);
    border-bottom: 1px solid var(--grey-200);
    background: var(--grey-50);
    position: sticky;
    top: 0;

    form {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      gap: 8px;
    }
  }

  @media (max-width: 700px) {
    .gallery-grid {
      padding: 24px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      width: 100%;
      overflow-y: visible;
    }

    .gallery-upload {
      padding: 24px;
      top: -30px;

      form > button {
        margin-left: auto;
      }
    }
  }
`;
